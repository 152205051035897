// import 'bootstrap';
import AOS from 'aos';

import { Fancybox } from "@fancyapps/ui";

import GLightbox from "glightbox";

const lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true,
    openEffect: "zoom",
    closeEffect: "fade",
    cssEfects: {
        // This are some of the animations included, no need to overwrite
        fade: { in: "fadeIn", out: "fadeOut" },
        zoom: { in: "zoomIn", out: "zoomOut" },
    },
});

document.documentElement.style.setProperty('--viewport-width', `${document.documentElement.clientWidth || document.body.clientWidth}px`);
window.addEventListener('resize', (e) => {
    document.documentElement.style.setProperty('--viewport-width', `${document.documentElement.clientWidth || document.body.clientWidth}px`);
});

AOS.init();

document.addEventListener('DOMContentLoaded', () => {
    AOS.refresh();
});


document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        const targetId = this.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);

        window.scrollTo({
            top: targetElement.offsetTop,
            behavior: 'smooth'
        });
    });
});