class AccordionComponent {
    constructor(accordions) {
        this.accordions = [...accordions];
        this.activeClass = "active";

        if (this.accordions.length <= 0) {
            // check if contains elements
            return;
        }
    }

    activeElement(el) {
        el.classList.add(this.activeClass);

        const accordionContent = el.querySelector("[data-accordion-content]");
        accordionContent.style.height = `${el.getAttribute(
            "data-accordion-height"
        )}px`;

        if (el.classList.contains(this.activeClass)) {
            (function () {
                accordionContent.addEventListener(
                    "transitionend",
                    () => {
                        accordionContent.style.height = "auto";
                    },
                    { once: true }
                );
            })();
        }
    }

    removeActive(el) {
        const that = this;

        const accordionContent = el.querySelector("[data-accordion-content]");
        accordionContent.style.height = `${el.getAttribute(
            "data-accordion-height"
        )}px`;

        setTimeout(() => {
            accordionContent.style.height = `${0}px`;
        }, 10);

        el.classList.remove(that.activeClass);

        // accordionContent.addEventListener('transitionend', () => {
        //     el.classList.remove(that.activeClass);
        //     accordionContent.style.height = `${0}px`;
        // },  { once: true });
    }

    showAll() {
        const that = this;

        this.accordions.forEach((accordion, index) => {
            let accordionContent = accordion.querySelector(
                "[data-accordion-content]"
            );
            accordion.setAttribute(
                "data-accordion-height",
                accordionContent.offsetHeight
            );
            accordionContent.style.height = `${0}px`;

            that.accordions.forEach((accordion) => {
                that.activeElement(accordion);
            });

            (function () {
                accordion.addEventListener("click", function () {
                    if (this.classList.contains(that.activeClass)) {
                        that.removeActive(accordion);
                    } else {
                        that.activeElement(accordion);
                    }
                });
            })();
        });
    }

    showFirst() {
        const that = this;
        this.accordions.forEach((accordion, index) => {
            let accordionContent = accordion.querySelector(
                "[data-accordion-content]"
            );
            accordion.setAttribute(
                "data-accordion-height",
                accordionContent.offsetHeight
            );
            accordionContent.style.height = `${0}px`;

            // Set first element to active
            // if(index === 0){
            //     that.activeElement(accordion);
            // }

            (function () {
                // Removes all active states
                accordion.addEventListener("click", function () {
                    if (this.classList.contains(that.activeClass)) {
                        that.removeActive(accordion);
                    } else {
                        that.activeElement(accordion);
                    }
                });
            })();
        });
    }
}

const accordionComponents = new AccordionComponent(
    document.querySelectorAll("[data-accordion]")
);
accordionComponents.showFirst();
