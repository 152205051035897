import gsap from "gsap";

function animatedLogo() {
    const logoTL = gsap.timeline();
    const logoPath = document.querySelector(".logo-animation");
    const logoPathSecond = document.querySelector(".logo-animation-2");

    if (!logoPath || !logoPathSecond) {
        return;
    }

    logoTL.to(logoPath, {
        "clip-path": "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
        transform: "scaleY(1)",
        ease: "power2.out",
        duration: 0.7,
    });

    logoTL.to(
        logoPathSecond,
        {
            "clip-path": "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
            ease: "expo.out",
            duration: 1,
        },
        "<20%"
    );
}

if (!document.body.classList.contains("wp-admin")) {
    animatedLogo();
}
