import Swiper from "../../node_modules/swiper/swiper-bundle.esm";

(function () {
    const breakpoints = {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1280,
        xxl: 1680,
    };

    const effects = [
        "slide",
        "fade",
        "cube",
        "coverflow",
        "flip",
        "creative",
        "cards",
    ];

    const swiperSizes = {
        fourth: 4,
        half: 2,
        third: 3,
        full: 1,
    };

    const swiperOptions = {
        // Optional parameters
        direction: "horizontal",
        loop: true,
        autoHeight: false,
        slideClass: "swiper__slide",
        createElements: true,
        watchOverflow: true,
        autoplay: false,

        lazy: {
            // Disable preloading of all images
            preloadImages: false,
            // Enable lazy loading
            lazy: true,
            //  tell swiper to load images before they appear
            // loadPrevNext: true,
            // amount of images to load
            // loadPrevNextAmount: 2,
            // Disable preloading of all images
        },

        // Navigation arrows
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            disabledClass: "swiper__button--disabled",
        },

        speed: 500,
        spaceBetween: 40,
        effect: effects[0],
        grabCursor: true,
        pagination: [],

        breakpoints: {
            [`${breakpoints.xs}`]: {
                slidesPerView: 1.2,
                centeredSlides: true,
            },
            [`${breakpoints.lg}`]: {
                slidesPerView: 2,
                centeredSlides: false,
            },
        },
    };

    // const swipers = [...document.querySelectorAll('.swiper:not(.swiper--posts)')];
    const swipers = [...document.querySelectorAll(".swiper")];

    swipers.forEach((swiper) => {
        if (swiper.closest(".pane")) {
            if (swiper.classList.contains('swiper--xs')) {
                if (window.matchMedia('(max-width: 992px)')) {
                    swiperOptions.navigation = {
                        nextEl: swiper.closest(".pane").querySelector(".swiper__button--next"),
                        prevEl: swiper.closest(".pane").querySelector(".swiper__button--prev"),
                        disabledClass: "swiper__button--disabled",
                    };
                }
                else {
                    swiperOptions.navigation = {};
                }
            }
            else {
                if (window.matchMedia('(min-width: 992px)')) {
                    swiperOptions.navigation = {
                        nextEl: swiper.closest(".pane").querySelector(".swiper__button--next"),
                        prevEl: swiper.closest(".pane").querySelector(".swiper__button--prev"),
                        disabledClass: "swiper__button--disabled",
                    };
                }
                else {
                    swiperOptions.navigation = {};
                }
            }
        }
        // =-=-=-=-=-=-=-=-=-=-=-=- Begin default swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        Object.entries(swiperSizes).forEach(function (swiperSize) {
            const [key, value] = swiperSize;
            if (swiper.classList.contains(`swiper--${key}`)) {
                swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                    slidesPerView: value,
                    centeredSlides: false,
                };
            }
        });

        if (swiper.classList.contains("swiper--posts") || swiper.classList.contains("swiper--vacancies")) {
            swiperOptions.autoHeight = false;
            swiperOptions.loop = false;
            swiperOptions.autoplay = false;
            swiperOptions.speed = 500;
            swiperOptions.navigation = true;

            swiperOptions.pagination = {
                el: swiper.closest(".pane").querySelector(".swiper__progress"),
                type: "progressbar",
            };

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 1.1,
            };

            swiperOptions.breakpoints[`${breakpoints.md}`] = {
                slidesPerView: 2,
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 3.395,
            };

            swiperOptions.breakpoints[`${breakpoints.xxl}`] = {
                slidesPerView: 3.395,
                spaceBetween: 40,
            };
        }

        if (swiper.classList.contains("swiper--image")) {
            swiperOptions.autoHeight = true;
        }

        // =-=-=-=-=-=-=-=-=-=-=-=- End default swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        // =-=-=-=-=-=-=-=-=-=-=-=- Begin Override swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-
        // If you want to overwrite a swiper carousel do it between the "Begin overwrite swiper carousels" and "End overwrite swiper carousels"

        if (swiper.classList.contains("swiper--hero")) {
            swiperOptions.autoHeight = false;
            swiperOptions.slidesPerView = 1;

            // Overrides the default breakpoint options with nothing, so the above options are used
            swiperOptions.breakpoints[`${breakpoints.xs}`] = {};
            swiperOptions.breakpoints[`${breakpoints.lg}`] = {};
        }

        if (swiper.classList.contains("swiper--logo")) {
            swiperOptions.autoHeight = false;
            swiperOptions.loop = true;
            swiperOptions.slidesPerView = 8;
            swiperOptions.autoplay = {
                delay: 1,
                waitForTransition: false,
            };
            swiperOptions.navigation = false;
            swiperOptions.allowTouchMove = true;
            swiperOptions.grabCursor = true;
            swiperOptions.speed = 10000;

            swiperOptions.breakpoints = {
                [breakpoints.xs]: {
                    slidesPerView: 2,
                    centeredSlides: false,
                },
                [breakpoints.md]: {
                    slidesPerView: 4,
                    centeredSlides: false,
                    spaceBetween: 80,
                },
                [breakpoints.lg]: {
                    slidesPerView: 8,
                    centeredSlides: false,
                    spaceBetween: 80,
                },
            };

        }

        if (swiper.classList.contains("swiper--cases")) {
            swiperOptions.autoHeight = false;
            swiperOptions.navigation = true;
            swiperOptions.loop = false;
            swiperOptions.slidesPerView = 1.1;
            swiperOptions.autoplay = false;
            swiperOptions.speed = 500;
            swiperOptions.pagination = {
                el: swiper.querySelector(".swiper__progress"),
                type: "progressbar",
            };

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 1.1,
                spaceBetween: 20,
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 2,
                spaceBetween: 40,
            };

            swiperOptions.breakpoints[`${breakpoints.xxl}`] = {
                slidesPerView: 3,
                spaceBetween: 40,
            };
        }

        if (swiper.classList.contains("swiper--team")) {
            swiperOptions.slidesPerView = 1.2;
            swiperOptions.autoplay = false;
            swiperOptions.speed = 500;
            // swiperOptions.navigation = false;
            swiperOptions.allowTouchMove = true;
            swiperOptions.grabCursor = true;
            swiperOptions.centeredSlides = false;
            swiperOptions.loop = false;

            swiperOptions.navigation = {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
                disabledClass: "swiper__button--disabled",
            },

                swiperOptions.pagination = {
                    el: swiper
                        .closest(".pane--team")
                        .querySelector(".swiper__progress"),
                    type: "progressbar",
                };

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 1.3,
                spaceBetween: 20,
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 3,
                spaceBetween: 40,
            };

            swiperOptions.breakpoints[`${breakpoints.xl}`] = {
                slidesPerView: 3,
                spaceBetween: 40,
            };
        }

        if (swiper.classList.contains("swiper--specializations")) {
            swiperOptions.autoHeight = false;
            swiperOptions.loop = false;
            swiperOptions.slidesPerView = 1.2;
            swiperOptions.autoplay = false;
            swiperOptions.speed = 500;
            swiperOptions.pagination = {
                el: swiper.querySelector(".swiper__progress"),
                type: "progressbar",
            };

            swiperOptions.breakpoints[`${breakpoints.xs}`] = {
                slidesPerView: 1.15,
                spaceBetween: 20,
            };

            swiperOptions.breakpoints[`${breakpoints.lg}`] = {
                slidesPerView: 4,
                spaceBetween: 40,
            };
        }

        if (swiper.classList.contains("swiper--testimonials")) {
            swiperOptions.autoHeight = false;
            swiperOptions.loop = false;
            swiperOptions.slidesPerView = 1.2;
            swiperOptions.autoplay = false;
            swiperOptions.speed = 500;
            swiperOptions.navigation = {
                nextEl: swiper.closest(".pane--testimonials").querySelector(".swiper--xs .swiper-button-next"),
                prevEl: swiper.closest(".pane--testimonials").querySelector(".swiper--xs .swiper-button-prev"),
                disabledClass: "swiper__button--disabled"
            };

            swiperOptions.breakpoints = {
                [breakpoints.xs]: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                [breakpoints.lg]: {
                    slidesPerView: "auto",
                    spaceBetween: 0,
                    direction: "vertical",
                    initialSlide: swiper.querySelectorAll(".swiper__slide").length,
                    navigation: {
                        nextEl: swiper.closest(".pane--testimonials").querySelector(".swiper-button-next"),
                        prevEl: swiper.closest(".pane--testimonials").querySelector(".swiper-button-prev"),
                        disabledClass: "swiper__button--disabled"
                    },
                },
            };
        }

        // =-=-=-=-=-=-=-=-=-=-=-=- End Override swiper carousels =-=-=-=-=-=-=-=-=-=-=-=-

        const swiperDefault = new Swiper(swiper, swiperOptions);
    });
})();
