function disciplinesAnimation(el) {
    const disciplines = document.querySelectorAll(el);
    const mobileMql = window.matchMedia("(max-width: 1280px)");

    if (!disciplines || document.body.classList.contains("wp-admin")) {
        return;
    }

    if (mobileMql.matches) {
        for (const discipline of disciplines) {
            removeEvent(discipline);
        }
    } else {
        for (const discipline of disciplines) {
            discipline.addEventListener("mouseenter", startEvent(discipline));
        }
    }

    mobileMql.addEventListener("change", (e) => {
        if (e.matches) {
            for (const discipline of disciplines) {
                removeEvent(discipline);
            }
        } else {
            for (const discipline of disciplines) {
                discipline.addEventListener(
                    "mouseenter",
                    startEvent(discipline)
                );
            }
        }
    });

    function removeEvent(discipline) {
        discipline.removeEventListener("mousemove", paneMouseMove);
        discipline.removeEventListener("mouseleave", paneMouseLeave);
    }

    function startEvent(discipline) {
        discipline.addEventListener("mousemove", paneMouseMove);
        discipline.addEventListener("mouseleave", paneMouseLeave);
    }

    function paneMouseMove(e) {
        let xpos =
            0 -
            (e.clientX -
                this.querySelector(
                    this.getAttribute("data-animation-container-move-element")
                ).offsetWidth /
                    2 +
                125);
        let ypos =
            0 -
            (e.clientY -
                this.querySelector(
                    this.getAttribute("data-animation-container-move-element")
                ).offsetHeight /
                    2);

        if (window.matchMedia("(max-width: 1280px)").matches) {
            this.querySelector(
                this.getAttribute("data-animation-container-move-element")
            ).style.transform = `translate(${
                xpos / (window.innerWidth / 30)
            }%, ${ypos / 75}%)`;
        } else if (window.matchMedia("(min-width: 1280px)").matches) {
            this.querySelector(
                this.getAttribute("data-animation-container-move-element")
            ).style.transform = `translate(${
                xpos / (window.innerWidth / 20)
            }%, ${ypos / 125}%)`;
        } else if (window.matchMedia("(max-width: 992px)").matches) {
            this.querySelector(
                this.getAttribute("data-animation-container-move-element")
            ).style.transform = "";
        }
    }

    function paneMouseLeave(e) {
        this.querySelector(
            this.getAttribute("data-animation-container-move-element")
        ).style.transform = "";
    }
}

disciplinesAnimation("[data-animation-container-move]");
