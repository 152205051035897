function mainMenuHoverAnimation(options) {
    let menuItems = Array.from(document.querySelectorAll(options.tabMenuItem));
    let activeSubmenu = null;

    let menuItemsObj = menuItems.map((item) => {
        let attribute = item.getAttribute("data-menu-item-hover-index");
        let submenu = `[data-menu-item-group-index="${attribute}"]`;

        return {
            item,
            submenu,
            id: attribute,
        };
    });

    menuItemsObj.forEach((menuItem, index) => {
        menuItem.item.addEventListener("click", (event) => {
            onEnter(menuItem);

            if(menuItem.item.classList.contains('menu__item--tabs-parent')) {
                event.preventDefault();
            }

        });
        // Trigger click event for the first item
        if (index === 0) {
            menuItem.item.click();
        }
    });

    function onEnter(menuItem) {
        // Set active menu item
        if (activeSubmenu) {
            onLeave(activeSubmenu);
        }

        activeSubmenu = menuItem;

        let container = document.querySelector(menuItem.submenu);
        if (container) {
            // Add menuHeight
            if (container.parentNode) {
                container.parentNode.style.height = `${container.offsetHeight}px`;
            }

            menuItem.item.classList.add("is-open");
            container.classList.add("is-open");
        }
    }

    function onLeave(menuItem) {
        let container = document.querySelector(menuItem.submenu);
        if (container) {
            container.classList.remove("is-open");
            menuItem.item.classList.remove("is-open");
        }
    }
}

mainMenuHoverAnimation({
    mainMenuItems: "[data-menu-tabs]",
    tabMenuItem: "[data-menu-item-hover-index]",
});
