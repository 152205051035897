const filterToggles = document.querySelectorAll('.btn--filter-toggle');
const filterSidebar = document.querySelector('.filter--mobile');

if( filterSidebar && filterToggles) {
    filterToggles.forEach(toggle => {
        toggle.addEventListener('click', () => {
            filterSidebar.classList.toggle('active');
        });
    });
} 
document.addEventListener('facetwp-loaded', function() {
var radioButtons = document.querySelectorAll('.facetwp-radio');
radioButtons.forEach(function(radioButton) {
  radioButton.addEventListener('click', function() {
    var categoryValue = this.getAttribute('data-value'); // Get the value of the clicked radio button
    var facet_name = this.closest('.facetwp-facet').getAttribute('data-name'); // Get the data-name attribute of the closest .facetwp-facet ancestor
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': facet_name,
      'category': categoryValue // Set the category to the value of the clicked radio button
    });
  });
});
});