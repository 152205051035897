import Macy from "macy";

function masonry(container) {
    let masonryContainer = document.querySelector(container);
    if (!masonryContainer) {
        return;
    }

    return new Macy({
        container: container,
        trueOrder: false,
        waitForImages: true,
        margin: 48,
        columns: 1,
        mobileFirst: true,
        breakAt: {
            768: {
                columns: 2,
            },
        },
    });
}

const casesMasonry = masonry(".grid-cases");

if (casesMasonry) {
    document.addEventListener("facetwp-loaded", function () {
        casesMasonry.recalculate(true);
    });
}
