class MouseFollow {
    constructor(mouseFollowWrappers) {
        if(! mouseFollowWrappers) return;

        this.mouseFollowWrappers = [...mouseFollowWrappers];

        if (this.mouseFollowWrappers.length <= 0) {
            // check if contains elements
            return;
        }

        this.animate();
    }

    animate() {
        this.mouseFollowWrappers.forEach(wrapper => {
            const mouseFollowElement = wrapper.querySelector(wrapper.getAttribute('data-mouse-follow-element'));

            if(mouseFollowElement) {
                wrapper.addEventListener("mousemove", (e) => {
                    let posY =
                        e.clientY -
                        wrapper.getBoundingClientRect().top -
                        (wrapper.offsetHeight / 2 - mouseFollowElement.offsetHeight / 2);
                    let posX =
                        e.clientX -
                        wrapper.getBoundingClientRect().left -
                        (wrapper.offsetWidth / 2 - mouseFollowElement.offsetWidth / 2);
            
                    mouseFollowElement.style.transform = `translate(${posX / 10}%, ${posY / 10}%)`;
                });
            
                wrapper.addEventListener("mouseleave", () => {
                    mouseFollowElement.style.transform = `translate(0%, 0%)`;
                });
            }
        });
    }
}

const MouseFollowAnimation = new MouseFollow(
    document.querySelectorAll('[data-mouse-follow]')
);
