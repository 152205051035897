const InfiniteScroll = require('infinite-scroll');
const infiniteContainer = document.querySelector("[data-infinite-container]");

if(infiniteContainer){
    infiniteContainer.addEventListener('DOMNodeInserted', function(e) {
        if(e.target instanceof Element){
            e.target.classList.add('fade-in');
        }
    });
}

if(InfiniteScroll && infiniteContainer){
    const infScroll = new InfiniteScroll('[data-infinite-container]', {
        path: '.next',
        append: '[data-infinite-child]',
        history: false,
    });
}