
function subMenuClick(options) {
    let parentMenuItems = document.querySelectorAll(options.parentMenuItem);

    parentMenuItems.forEach((menuItem) => {
        menuItem.addEventListener("click", (event) => {
            if(event.currentTarget !== event.target) {
                return;
            }
            // Remove is-active class from all menu items
            parentMenuItems.forEach((item) => {
                if(menuItem !== item) {
                    item.classList.remove('is-open');
                }
            });
            
            if(menuItem.classList.contains('is-open')) {
                menuItem.classList.remove('is-open');
            } else {
                // Add is-active class only to the clicked menu item
                menuItem.classList.add('is-open');
            }

            event.preventDefault();
        });
    });
}

subMenuClick({
    parentMenuItem: ".menu__item--parent",
});