const colText = document.querySelectorAll('.col--text');

const observer = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('active');
            }
        });
    },
    { 
        threshold: 1.0, 
        rootMargin: "-20% 0%"
    }
);

if(colText) {
    colText.forEach(col => {
        observer.observe(col);
    });
}
