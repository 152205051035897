import gsap from 'gsap'
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function parallax(
    selector
) {
    if(!selector) {
        return;
    }

    const timeline = gsap.timeline({
        scrollTrigger: {
            start: "top bottom",
            end: "bottom center",
            trigger: selector.closest('.pane'),
            scrub: true,
            markers: false
        }
    });

    timeline.fromTo(selector, {
        yPercent: 0,
        ease: 'none'
    },{
        yPercent: -20,
        ease: 'none'
    });
}
