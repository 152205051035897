class GridHelper {
    active() {
        document.body.insertAdjacentHTML(
            "beforeEnd",
            `
            <div class="container container--overlay-grid">
                <div class="row">
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                    <div class="col"><div class="inner"></div></div>
                </div>
            </div>
        `
        );
    }

    destroy() {
        document.querySelector(".container--overlay-grid").remove();
    }
}

let gridHelper = new GridHelper();
window.GridHelper = gridHelper;

document.addEventListener('keydown', (e) => {
    // key L = 76

    if(e.ctrlKey && e.keyCode === 76) {
        if (document.querySelector('.container--overlay-grid')) {
            window.GridHelper.destroy();
        }
        else {
            window.GridHelper.active();
        }
    }
});

// Useage in the console of the browser
// window.GridHelper.active();
// window.GridHelper.destroy();
