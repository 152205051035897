import { imageReveal } from './modules/gsap/imageReveal';
import { textReveal } from './modules/gsap/textReveal';
import { parallax } from './modules/gsap/parallax';

const imageRevealPanes = [
    '.pane--two_column_text_image_testimonial',
    '.pane--two_column_text_image'
];

if(!document.body.classList.contains('wp-admin')) {
    imageReveal(imageRevealPanes);

    const myObserver = new IntersectionObserver(elements => {
        if(elements[0].isIntersecting) {
            textReveal(elements[0].target.querySelector('.pane__text h1, .pane__text h2, .pane__text h3, .pane__title h1, .pane__title h2, .pane__title h3'));
        }
    });

    const panes = document.querySelectorAll('.pane');

    panes.forEach(pane => {
        myObserver.observe(pane);
    });

    const tcmpTestimonials = document.querySelectorAll('.pane--two_column_text_image_testimonial');

    tcmpTestimonials.forEach(pane => {
        parallax(pane.querySelector('.card--review'));
    });
}
