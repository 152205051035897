class Popup {
    constructor(popup) {
        this.popup = popup;
        this.popupOpen = document.querySelector('.popup__open');
        this.popupClose = document.querySelector('.popup__close');
        
        if (!this.popup || !this.popupOpen || !this.popupClose) {
            return;
        }
        this.showPopupDelay = this.popup.getAttribute('data-timeout');
        
        this.init();
    }

    init() {
        if (!localStorage.getItem("popup")) {
            localStorage.setItem("popup", "1");
        }

        this.popupOpen.addEventListener('click', () => {
            this.toggle(true);
        });

        this.popupClose.addEventListener('click', () => {
            this.toggle(false);
        });

        const that = this;
        setTimeout(function () {
            that.toggle(localStorage.getItem("popup"));
        }, this.showPopupDelay);
    }

    toggle(open) {
        if (open == true) {
            this.popup.classList.add('is-active');
            localStorage.setItem("popup", "1");
        } else {
            this.popup.classList.remove('is-active');
            localStorage.setItem("popup", "0");
        }
    }
}

new Popup(document.querySelector('.popup'));
