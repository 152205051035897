class headerObserver {
    constructor(parentEl) {
        if (parentEl) {
            this.parentEl = parentEl;
            this.options = {};
            this.startObserve();
        } else {
            return;
        }
    }

    startObserve() {
        const parentEl = this.parentEl;

        let observer = new IntersectionObserver((entries) => {
            if (entries[0].boundingClientRect.y < 0) {
                parentEl.classList.add("main-header--observed");
            } else {
                parentEl.classList.remove("main-header--observed");
            }
            // }
        });
        observer.observe(document.querySelector("#pixel-to-watch"));
    }
}

if(window.innerWidth >= 992 ) {
    const menuObserver = new headerObserver(
        document.querySelector("[data-header]")
    );
}