import gsap from 'gsap'
import { SplitText } from 'gsap/dist/SplitText';

gsap.registerPlugin(SplitText);

export function textReveal(
    selector,
    timeline = gsap.timeline()
) {
    if(!selector) {
        return;
    }

    var childSplit = new SplitText(selector, {
        type: "words",
        linesClass: "split-child"
    });
    
    var parentSplit = new SplitText(selector, {
        type: "words",
        linesClass: "split-parent"
    });

    timeline.from(childSplit.words, {
        duration: 1.5,
        yPercent: 100,
        ease: "power4",
        stagger: 0.02
    });
}
