import gsap from 'gsap'
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function imageReveal(
    elements,
) {
    if(elements.length > 0) {
        elements.forEach(element => {
            const panes = document.querySelectorAll(element);

            panes.forEach(pane => {
                const timeline = gsap.timeline({
                    scrollTrigger: {
                        start: "top center",
                        trigger: pane,
                        once: true
                    }
                });

                timeline.fromTo(pane.querySelectorAll('.img-fluid'), {
                    clipPath: 'polygon(0% 0%, 00% 0%, 0% 100%, 0% 100%)',
                },{
                    ease: "power4.out",
                    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
                    stagger: 0.5,
                    duration: 1,
                });
            });
        });
    }
}
