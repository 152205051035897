const caseStatistics = document.querySelector('.case-statistics');

if (caseStatistics) {
    const statisticsItems = caseStatistics.querySelectorAll('.case-statistics__item');

    statisticsItems.forEach(item => {
        item.querySelector('.percentage').setAttribute('data-percentage', item.querySelector('.percentage').textContent.replace('%',''));
        item.querySelector('.percentage').textContent = '0';

        countUp(item, item.querySelector('.percentage').textContent);
    });
}

function countUp(item, count){
    item.querySelector('.percentage').textContent = `${count}`;

    if (Number(count) < Number(item.querySelector('.percentage').getAttribute('data-percentage'))) {
        setTimeout(() => countUp(item, Number(count) + 1), 15);
    }
}
